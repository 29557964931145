<template>
  <div>
    <my-nav-bar
      title="客户下单"
      left-text="返回"
      left-arrow
    />
    <div class="filter">
      <van-cell title="选择客户" :value="client.name" is-link center @click="toClientView" />
      <van-cell title="添加商品" :value="goodsList.length > 0 ? `已添加${goodsList.length}种` : ''" is-link center @click="toGoodsView" />
    </div>
    <div class="list">
      <van-swipe-cell v-for="(goods, index) in goodsList" :key="goods.id">
        <div class="goods">
          <div class="image">
            <van-image :src="goods.thumb_image" width="2.13333rem" height="2.13333rem" />
          </div>
          <div class="info">
            <div>{{ goods.name }}</div>
            <div class="size">规格：1 * {{ goods.size }}</div>
            <div class="size">
              <span>数量：</span>
              <span v-if="goods.large_quantity">{{ goods.large_quantity }}{{ goods.large_unit }}</span>
              <span v-if="goods.least_quantity">{{ goods.least_quantity }}{{ goods.least_unit }}</span>
            </div>
            <div class="price">小计：￥{{ goods.amount }}</div>
          </div>
        </div>
        <template #right>
          <van-button square type="danger" class="del-btn" text="删除" @click="handleDelete(index)" />
        </template>
      </van-swipe-cell>
    </div>
    <div class="fixed-bottom-btn">
      <van-button :disabled="goodsList.length === 0" square block @click="handleSubmit">
        提交订单
        <span v-if="goodsList.length > 0">
          （合计￥{{ goodsAmount }}）
        </span>
      </van-button>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { unitsToVolume, volumeToUnits } from '@/utils'
import { submitOrder } from '@/api/salesman-order'
export default {
  name: 'SalesmanOrder',
  components: { myNavBar },
  data() {
    return {
      client: {
        id: null,
        name: ''
      },
      goodsList: [],
      goodsAmount: 0
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
    this.$eventBus.$on('select-client', client => {
      this.client = client
    })
    this.$eventBus.$on('add-order-goods', goods => {
      const hasGoods = this.goodsList.find(g => g.id === goods.id)
      if (hasGoods) {
        const largeQuantity = Number(hasGoods.large_quantity) + Number(goods.large_quantity)
        const leastQuantity = Number(hasGoods.least_quantity) + Number(goods.least_quantity)
        const quantity = unitsToVolume(largeQuantity, leastQuantity, hasGoods.size)
        const { largeVolume, leastVolume } = volumeToUnits(quantity, hasGoods.size)
        hasGoods.large_quantity = largeVolume
        hasGoods.least_quantity = leastVolume
        hasGoods.quantity = quantity
        hasGoods.amount = (quantity * hasGoods.price).toFixed(2)
      } else {
        const quantity = unitsToVolume(goods.large_quantity, goods.least_quantity, goods.size)
        const { largeVolume, leastVolume } = volumeToUnits(quantity, goods.size)
        goods.large_quantity = largeVolume
        goods.least_quantity = leastVolume
        goods.quantity = quantity
        goods.amount = (quantity * goods.price).toFixed(2)
        this.goodsList.push(goods)
      }

      let amount = 0
      this.goodsList.forEach(goods => {
        amount += Number(goods.amount)
      })

      this.goodsAmount = amount.toFixed(2)
      this.$forceUpdate()
    })
  },
  // beforeRouteLeave(to, from, next) {
  //   // 清除缓存
  //   if (to.path !== '/salesman-order-client' && to.path !== '/salesman-order-goods') {
  //     this.$store.commit('rmKeepAlive', this.$options.name)
  //     // 取消监听
  //     this.$eventBus.$off('select-client')
  //     this.$eventBus.$off('add-order-goods')
  //   }
  //   next()
  // },
  methods: {
    toClientView() {
      this.$router.push({
        path: 'salesman-order-client'
      })
    },
    toGoodsView() {
      if (!this.client.id) {
        this.fail('请选择客户')
        return
      }

      this.$router.push({
        path: 'salesman-order-goods',
        query: {
          client_id: this.client.id
        }
      })
    },
    handleSubmit() {
      this.confirm('确定提交订单吗？', '提示').then(() => {
        const data = {
          client_id: this.client.id,
          goods_list: this.goodsList
        }
        this.beginLoad()
        submitOrder(data).then(res => {
          this.success(res.msg)
          this.client = {
            id: null,
            name: ''
          }
          this.goodsList = []
          this.goodsAmount = 0
        })
      })
    },
    handleDelete(index) {
      this.confirm('确定要删除商品吗？', '提示').then(() => {
        this.goodsList.splice(index, 1)
        let amount = 0
        this.goodsList.forEach(goods => {
          amount += Number(goods.amount)
        })

        this.goodsAmount = amount.toFixed(2)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter {
    position: fixed;
    left: 0;
    top: 46px;
    right: 0;
    z-index: 9;
  }
  .list {
    padding: 144px 10px 0 10px;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 50px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 50px);
  }
  .goods {
    background-color: #fff;
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
  }
  .image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .size {
    font-size: 12px;
    color: #989898;
  }
  @import '@/styles/color.scss';
  .price {
    font-size: 12px;
    color: $amountRedColor;
  }
  .del-btn {
    height: 100%;
  }
</style>
