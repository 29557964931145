var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "客户下单",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "filter"
  }, [_c('van-cell', {
    attrs: {
      "title": "选择客户",
      "value": _vm.client.name,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.toClientView
    }
  }), _c('van-cell', {
    attrs: {
      "title": "添加商品",
      "value": _vm.goodsList.length > 0 ? "\u5DF2\u6DFB\u52A0".concat(_vm.goodsList.length, "\u79CD") : '',
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.toGoodsView
    }
  })], 1), _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.goodsList, function (goods, index) {
    return _c('van-swipe-cell', {
      key: goods.id,
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            staticClass: "del-btn",
            attrs: {
              "square": "",
              "type": "danger",
              "text": "删除"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDelete(index);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "goods"
    }, [_c('div', {
      staticClass: "image"
    }, [_c('van-image', {
      attrs: {
        "src": goods.thumb_image,
        "width": "2.13333rem",
        "height": "2.13333rem"
      }
    })], 1), _c('div', {
      staticClass: "info"
    }, [_c('div', [_vm._v(_vm._s(goods.name))]), _c('div', {
      staticClass: "size"
    }, [_vm._v("规格：1 * " + _vm._s(goods.size))]), _c('div', {
      staticClass: "size"
    }, [_c('span', [_vm._v("数量：")]), goods.large_quantity ? _c('span', [_vm._v(_vm._s(goods.large_quantity) + _vm._s(goods.large_unit))]) : _vm._e(), goods.least_quantity ? _c('span', [_vm._v(_vm._s(goods.least_quantity) + _vm._s(goods.least_unit))]) : _vm._e()]), _c('div', {
      staticClass: "price"
    }, [_vm._v("小计：￥" + _vm._s(goods.amount))])])])]);
  }), 1), _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.goodsList.length === 0,
      "square": "",
      "block": ""
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" 提交订单 "), _vm.goodsList.length > 0 ? _c('span', [_vm._v(" （合计￥" + _vm._s(_vm.goodsAmount) + "） ")]) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }